<template>
  <div class="about">
    <template v-if="this.activeName === 'first'">
      <first></first>
    </template>
    <template v-if="this.activeName === 'second'">
      <second></second>
    </template>
    <template v-if="this.activeName === 'third'">
      <third></third>
    </template>
  </div>
</template>

<script>
import first from 'components/First'
import second from 'components/Second'
import third from 'components/Third'

export default {
  name: 'Plan',
  components: {
    first,
    second,
    third
  },
  data () {
    return {
      activeName: ''
    }
  },
  mounted () {
    if (this.$route.params.code) {
      this.activeName = this.$route.params.code
    }
  }
}
</script>

<style scoped>
.about-header {
  text-align: center;
  margin-top: 60px;
}
.about-header-title {
  font-size: 48px;
  color: var(--color-text);
}
.about-header-label {
  font-size: 16px;
  margin-top: 20px;
  color: var(--color-text);
}
.about-tab {
  margin-top: 60px;
  margin-bottom: 60px;
}
:deep(.el-tabs__nav) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  float: none;
  height: 170px;
}
:deep(.el-tabs__nav-wrap::after) {
  height: 0;
}
:deep(.el-tabs__active-bar) {
  height: 0;
}
:deep(.el-tabs__item) {
  width: 393px;
  height: 60px;
  text-align: center;
  border: 1px solid #808080;
  padding: 0;
  font-size: 24px;
  color: var(--color-text);
  line-height: 60px;
  font-weight: normal;
}
:deep(.el-tabs__item.is-active, .el-tabs__item:hover) {
  color: #fff;
  background-color: var(--default-color);
  border-color: var(--default-color);
}
:deep(.el-tabs__item.is-active::after) {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 58px;
  transform: translate(-50%, 0);
  width: 0;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 10px solid var(--default-color);
}
.about-tab-item {
  margin-top: 25px;
}
.about-tab-content {
  display: flex;
}
.about-tab-content-pic {
  width: 590px;
  margin-right: 20px;
}
.about-tab-info {
  flex: 1;
}
.about-tab-info-title {
  color: var(--color-text);
  font-size: 30px;
  padding-top: 28px;
}
.about-tab-info-desc {
  font-size: var(--font-size);
  color: var(--color-text);
  margin-top: 30px;
  line-height: 24px;
}
.plan-other {
  padding-bottom: 60px;
}
.other-title {
  color: var(--color-text);
  font-size: 30px;
  text-align: center;
}
.other-title span {
  color: #C81125;
}
.other-mobile {
  margin-top: 40px;
  color: var(--color-text);
  font-size: 60px;
  text-align: center;
}
.other-tip {
  color: var(--color-text);
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.index-pro-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.index-pro-item {
  margin-top: 30px;
  width: 380px;
  height: 324px;
  padding: 15px;
  background-color: #fafafa;
  border: 1px solid #E5E5E5;
  cursor: pointer;
}
.index-pro-item:hover {
  box-shadow: 0 0 6px 0 rgba(179, 179, 179, 0.5);
}
.index-pro-item-pic {
  width: 350px;
  height: 208px;
}
.index-pro-item-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}
.index-pro-item-desc {
  margin-top: 8px;
  font-size: var(--font-size);
  color: #b3b3b3;
  line-height: 18px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.index-pro-btn {
  margin-top: 40px;
  text-align: center;
}
.index-pro-btn-more {
  width: 220px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-radius: 8px;
  background-color: #C81125;
}
</style>
