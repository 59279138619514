<template>
  <div class="first">
    <div class="first-top">
      <div class="first-top-box df-width">
        <div class="first-top-one">
          <div class="first-top-one-title">连锁企业管理系统</div>
          <div class="first-top-one-desc">高效门店管理，收款分账一步到位</div>
          <div class="first-top-one-content">
            <div>连锁企业管理系统专注于本地化的企业服务，运用互联网信息化技术为企业提供人员管理、门店管理、商品管理、财务管理等服务。</div>
            <div>系统界面美观、操作简单、功能便捷，直接对接银行账户，支持一键收款、快速分账，支持单门店和多门店连锁作业，让企业实现“高效的门店管理、收款分账一步到位”。</div>
            <div>系统还可根据企业需要进行定制化开发，为企业的日常运营和管理量身打造服务功能。以最强大的信息技术提供支持，随时随地掌控信息，业务处理有条不紊！</div>
          </div>
          <div class="first-top-one-btn" @click="goToUrl('/contact.html')">联系我们</div>
        </div>
        <div class="first-top-two">
          <el-image :src="top_pic" style="width: 484px; height: 360px;"></el-image>
        </div>
      </div>
    </div>
    <div class="first-center df-width">
      <div class="first-center-box">
        <el-image :src="center2" style="width: 620px; height: 497px;"></el-image>
        <div class="first-center-logo">
          <el-image :src="center1" style="width: 351px; height: 297px;"></el-image>
          <div class="first-center-label">门店管理痛点</div>
          <div class="first-center-desc">Pain points of store management</div>
        </div>
        <div class="first-center-info one">
          <div>门店资质不完善</div>
          <div>个人收款码不规范</div>
        </div>
        <div class="first-center-info two">
          <div>管理方式不一致</div>
          <div>财务报表不透明</div>
        </div>
        <div class="first-center-info three">
          <div>数据统计不智能</div>
          <div>分账层级多杂乱</div>
        </div>
        <div class="first-center-info four">
          <div>人员管理不方便</div>
          <div>信息更新不及时</div>
        </div>
        <div class="first-center-info five">
          <div>商品售价不统一</div>
          <div>优惠折扣理不清</div>
        </div>
      </div>
    </div>
    <div class="first-bottom">
      <div class="df-width">
        <div class="first-bottom-label">系统解决方案</div>
        <div class="first-bottom-desc">SYSTEM SOLUTION</div>
        <div class="first-bottom-box">
          <div class="first-bottom-item">
            <div class="first-bottom-tip">
              <el-image :src="icon1" style="width: 60px; height: 60px;"></el-image>
              <div class="first-bottom-tip-info">
                <div class="first-bottom-tip-title">财务统计、店铺分账一码解决</div>
                <div class="first-bottom-tip-desc">
                  平台为企业提供专属的银行收款码，为门店解决个人码不规范收款的问题。同时能够自动统计各个门店的财务数据，多维度即时分账、形成报表。
                </div>
              </div>
            </div>
            <div class="first-bottom-tip first-bottom-one">
              <el-image :src="icon2" style="width: 60px; height: 60px;"></el-image>
              <div class="first-bottom-tip-info">
                <div class="first-bottom-tip-title">聚合多种支付方式</div>
                <div class="first-bottom-tip-desc">
                  支持微信、支付宝、云闪付支付。顾客快速支付，商家安全到账。
                </div>
              </div>
              <div class="first-bottom-tip-icon">
                <el-image :src="pay1" style="width: 48px; height: 42px"></el-image>
                <el-image :src="pay2" style="width: 48px; height: 48px"></el-image>
                <el-image :src="pay3" style="width: 48px; height: 48px"></el-image>
              </div>
            </div>
            <div class="first-bottom-tip">
              <el-image :src="icon3" style="width: 60px; height: 60px;"></el-image>
              <div class="first-bottom-tip-info">
                <div class="first-bottom-tip-title">总部统一管理，门店规范管理</div>
                <div class="first-bottom-tip-desc">
                  在线即可轻松解决企业各类管理难题。审批流程、费控及物资管理、日报周报、人事及绩效管理等，帮助大幅提升办公效率，做到降本增效。
                </div>
              </div>
            </div>
          </div>
          <div class="first-bottom-pic" style="margin-left: 47px;">
            <el-image :src="bottom1" style="width: 594px; height: 439px;"></el-image>
            <div class="first-bottom-pic-tab">
              <div class="bottom-tab green">生活类</div>
              <el-image :src="add" style="width: 20px; height: 20px;"></el-image>
              <div class="bottom-tab red">餐饮类</div>
              <el-image :src="add" style="width: 20px; height: 20px;"></el-image>
              <div class="bottom-tab orange">教育类</div>
            </div>
            <div class="bottom-tab-desc">各式各样的线上商城一网打尽</div>
          </div>
        </div>

        <div class="first-bottom-box">
          <div class="first-bottom-pic" style="margin-right: 30px;">
            <el-image :src="bottom2" style="width: 609px; height: 425px;"></el-image>
          </div>
          <div class="first-bottom-item" style="display: flex; flex-direction: column;align-items: end;">
            <div class="first-bottom-tip">
              <el-image :src="icon4" style="width: 60px; height: 60px;"></el-image>
              <div class="first-bottom-tip-info">
                <div class="first-bottom-tip-title">适用于各类应用场景</div>
                <div class="first-bottom-tip-desc">
                  系统通用于各类场景，提供OA管理、CRM客户管理、进销存管理、生产管理、工程项目管理等服务。助您完成业务流程，精准触达客户，大幅提高赢单效率。
                </div>
              </div>
            </div>
            <div class="first-bottom-tip first-bottom-two">
              <el-image :src="icon5" style="width: 60px; height: 60px;"></el-image>
              <div class="first-bottom-tip-info">
                <div class="first-bottom-tip-title">可视化界面，数据反馈更清晰</div>
                <div class="first-bottom-tip-desc">
                  通过直观的图表对数据进行可视化呈现，使用户快捷掌握关键信息，然后再通过周边的指标来进一步反馈数据指标，为决策提供依据支撑。
                </div>
              </div>
            </div>
            <div class="first-bottom-tip">
              <el-image :src="icon6" style="width: 60px; height: 60px;"></el-image>
              <div class="first-bottom-tip-info">
                <div class="first-bottom-tip-title">定制化开发，量身打造管理系统</div>
                <div class="first-bottom-tip-desc">
                  为企业提供定制化系统服务，量身定制功能模块。供版本优化升级、在线帮助。专业团队，专业技术，专业服务，为企业打造最省心、最便捷的连锁店管理系统。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'First',
  data () {
    return {
      top_pic: require('@/assets/images/plan_first1.png'),
      center1: require('@/assets/images/plan_first2.png'),
      center2: require('@/assets/images/plan_first3.png'),
      bottom1: require('@/assets/images/plan_first5.png'),
      add: require('@/assets/images/add.png'),
      icon1: require('@/assets/images/plan_first6.png'),
      icon2: require('@/assets/images/plan_first7.png'),
      icon3: require('@/assets/images/plan_first8.png'),
      pay1: require('@/assets/images/wxpay.png'),
      pay2: require('@/assets/images/alipay.png'),
      pay3: require('@/assets/images/ypay.png'),
      bottom2: require('@/assets/images/plan_first9.png'),
      icon4: require('@/assets/images/plan_first10.png'),
      icon5: require('@/assets/images/plan_first11.png'),
      icon6: require('@/assets/images/plan_first12.png')
    }
  },
  methods: {
    goToUrl (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
  .first-top {
    height: 400px;
    background-color: #4F7FC1;
    display: flex;
    align-items: center;
  }
  .first-top-box {
    display: flex;
    align-items: center;
  }
  .first-top-one {
    color: #fff;
    flex: 1
  }
  .first-top-one-title {
    font-size: 40px;
    font-weight: bold;
  }
  .first-top-one-desc {
    font-size: 20px;
    margin-top: 8px
  }
  .first-top-one-content {
    width: 600px;
    font-size: 14px;
    line-height: 25px;
    margin-top: 26px;
  }
  .first-top-one-btn {
    border: 1px solid #fff;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 30px;
    cursor: pointer;
  }
  .first-center {
    padding-top: 135px;
  }
  .first-center-box {
    width: 620px;
    height: 505px;
    position: relative;
    margin: auto;
  }
  .first-center-logo {
    position: absolute;
    text-align: center;
    color: #4F7FC1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .first-center-label {
    font-size: 40px;
    font-weight: bold;
    margin-top: 30px
  }
  .first-center-desc {
    font-size: 16px;
    margin-top: 10px;
  }
  .first-center-info {
    position: absolute;
    font-size: 24px;
    color: var(--color-text);
    line-height: 36px;
    text-align: center;
  }
  .first-center-info.one {
    left: -210px;
    bottom: 138px;
  }
  .first-center-info.two {
    left: -110px;
    top: 20px;
  }
  .first-center-info.three {
    left: 50%;
    top: -100px;
    transform: translate(-50%, 0);
  }
  .first-center-info.four {
    right: -110px;
    top: 20px;
  }
  .first-center-info.five {
    right: -190px;
    bottom: 138px;
  }
  .first-bottom-box {
    margin-top: 60px;
    display: flex;
    align-items: center;
  }
  .first-bottom {
    height: 1383px;
    background-image: url('~assets/images/plan_first4.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: -20px;
  }
  .first-bottom-label {
    color: #4F7FC1;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    padding-top: 115px;
    margin-bottom: 10px;
  }
  .first-bottom-desc {
    color: #4F7FC1;
    font-size: 16px;
    text-align: center;
  }
  .first-bottom-pic {
    width: 594px;
  }
  .first-bottom-pic-tab {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: -30px;
  }
  .bottom-tab {
    width: 120px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: #ccc;
    color: #fff;
    font-size: 20px;
  }
  .green {
    background-color: #21C729;
  }
  .red {
    background-color: #D31628;
  }
  .orange {
    background-color: #EB9D3C;
  }
  .bottom-tab-desc {
    font-size: 20px;
    color: var(--color-text);
    text-align: center;
    margin-top: 20px;
  }
  .first-bottom-item {
    flex: 1;
  }

  .first-bottom-tip {
    width: 500px;
    height: 140px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 0px rgba(35, 24, 21, 0.1);
    padding: 24px 20px;
    display: flex;
    align-items: flex-start;
  }
  .first-bottom-tip-info {
    margin-left: 20px;
    flex: 1;
  }
  .first-bottom-tip-title {
    color: #468CCB;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 9px;
  }
  .first-bottom-tip-desc {
    font-size: 14px;
    line-height: 21px;
    color: var(--color-text);
  }
  .first-bottom-one {
    margin: 40px 0 40px 60px;
  }
  .first-bottom-tip-icon {
    width: 172px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }
  .first-bottom-two {
    margin: 40px 60px 40px 0;
  }
</style>
