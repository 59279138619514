<template>
  <div class="third">
    <div class="third-top">
      <div class="third-top-box df-width">
        <div class="third-top-one">
          <div class="third-top-one-title">教培行业管理系统</div>
          <div class="third-top-one-desc">轻松招生，智能考勤，报班选课，一键就行</div>
          <div class="third-top-one-content">
            <div>教培行业管理系统，可以全面改善学校整个教学工作流程，使繁琐的课程管理，班级排课，学生选课、交费、续费、欠费、退费，学生插（转）班，学生考勤等问题得到完美的解决，大大缩短各个流程处理时间，并实现教学与财务、运营等信息交互应用。</div>
            <div>后台系统+教师端+家长端一站式平台，能够全方位帮助机构提升运营效果，数据看板、数据统计、数据监管、数据互通一站式服务机构，能为解决学校教务管理问题提供有效的数据支撑。</div>
          </div>
          <div class="third-top-one-btn" @click="goToUrl('/contact.html')">联系我们</div>
        </div>
        <div class="third-top-two">
          <el-image :src="top_pic" style="width: 350px; height: 319px;"></el-image>
        </div>
      </div>
    </div>
    <div class="third-center df-width">
      <div class="third-center-box">
        <div class="third-center-info">
          <div class="third-center-item">
            <div class="third-center-title">轻松招生</div>
            <div class="third-center-desc">
              系统精细化用户画像，个性化服务跟进，提高用户转化率；支持招生业绩目标设置，系统自动统计教师个人阶段业绩及排名，能激发教师挑战潜能。
            </div>
          </div>
          <div class="third-center-item">
            <div class="third-center-title">智能考勤</div>
            <div class="third-center-desc">
              支持在线一键考勤，学员所上课程、剩余课时清晰呈现、智能统计，学校省心家长更安心。
            </div>
          </div>
          <div class="third-center-item">
            <div class="third-center-title">智能排课</div>
            <div class="third-center-desc">
              后台智能排课，自动化冲突和异常检测；支持教师个性化设置课程科目，可根据生源灵活开班开课；系统根据排课及考勤结果，自动生成课程表，支持一健查看。
            </div>
          </div>
          <div class="third-center-item">
            <div class="third-center-title">报班选课</div>
            <div class="third-center-desc">
              支持在线选班报名，购课约课、退课转班、缴费支付在线完成，动动手指即可轻松完成报班约课操作，消费粘性轻松培养，大大提升服务体验及报班率。
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <el-image :src="pic1" style="width: 581px; height: 485px;"></el-image>
        </div>
      </div>

      <div class="third-center-box" style="margin-top: 35px;margin-bottom: 60px;">
        <div style="flex: 1;">
          <el-image :src="pic2" style="width: 558px; height: 484px;"></el-image>
        </div>
        <div class="third-center-info">
          <div class="third-center-item yellow">
            <div class="third-center-title">学员管理</div>
            <div class="third-center-desc">
              电子化管理学员信息，在读班级、上课记录、缺勤记录、考试成绩、剩余课程等学员信息详细记录一健可查，支持批量导入、导出操作。
            </div>
          </div>
          <div class="third-center-item yellow">
            <div class="third-center-title">消息通知</div>
            <div class="third-center-desc">
              实时推送上课提醒、考勤通知、课程调整、课程优惠等信息，方便家长、学员及时了解学校及教学情况，教学管理透明化，提高学校信任度。
            </div>
          </div>
          <div class="third-center-item yellow">
            <div class="third-center-title">财务管理</div>
            <div class="third-center-desc">
              学员缴费系统自动生成交费流水，交费、退费、结转、实收自动统计汇总，支持导出打印核对，确保每笔业务流水清晰记录可查。
            </div>
          </div>
          <div class="third-center-item yellow">
            <div class="third-center-title">数据统计</div>
            <div class="third-center-desc">
              系统自动统计财务流水、课室使用率、学员考勤、教师绩效等数据，智能汇总分析生成可视化图表，方便校区进行经营评估与业绩考核。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Third',
  data () {
    return {
      top_pic: require('@/assets/images/plan_third1.png'),
      pic1: require('@/assets/images/plan_third2.png'),
      pic2: require('@/assets/images/plan_third3.png')
    }
  },
  methods: {
    goToUrl (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
.third-top {
  height: 400px;
  background: #40B2BA;
  display: flex;
  align-items: center;
}
.third-top-box {
  display: flex;
  align-items: center;
}
.third-top-one {
  color: #fff;
  flex: 1
}
.third-top-one-title {
  font-size: 40px;
  font-weight: bold;
}
.third-top-one-desc {
  font-size: 20px;
  margin-top: 8px
}
.third-top-one-content {
  width: 700px;
  font-size: 14px;
  line-height: 25px;
  margin-top: 26px;
}
.third-top-one-btn {
  border: 1px solid #fff;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 30px;
  cursor: pointer;
}
.third-center-box {
  display: flex;
  margin-top: 60px;
  align-items: flex-start;
}
.third-center-info {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.third-center-item {
  width: 290px;
  height: 220px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(64, 178, 186, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
}
.yellow {
  box-shadow: 0px 2px 8px 0px rgba(243, 188, 76, 0.1);
}
@font-face {
  font-family: 'label';
  src: url('~assets/font/PangMenZhengDao.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.third-center-title {
  font-size: 30px;
  font-family: 'label' !important;
  font-weight: 400;
  color: #40B2BA;
}
.yellow .third-center-title {
  color: #F3BC4C;
}
.third-center-desc {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
</style>
