<template>
  <div class="second">
    <div class="second-top">
      <div class="second-top-box df-width">
        <div class="second-top-one">
          <div class="second-top-one-title">协会组织管理系统</div>
          <div class="second-top-one-desc">数字化协会管理，智能化运营体系</div>
          <div class="second-top-one-content">
            <div>协会组织管理系统可以对协会组织人员进行快速、高效的管理，为协会组织成员之间提供一个良好的信息交流平台，让成员可以展示自己的风采，及时了解协会组织的新动态，营造一个现代化的协会组织交流环境。</div>
            <div>随着社会的发展，信息在当今社会中起到越来越重要的作用。如何有效地管理信息，利用信息，使之能够为企业、社会服务是我国社会信息化的一个重要目的，而协会组织在行业信息沟通、政府和企业政策沟通方面正起着不可代替的作用。</div>
            <div>协会组织管理系统是一套具有高度可拓展性、可重用性和高度可维护性的管理系统，能够满足协会组织对信息的需求，能够更好地使信息为政府、企业、社会服务。</div>
          </div>
          <div class="second-top-one-btn" @click="goToUrl('/contact.html')">联系我们</div>
        </div>
        <div class="second-top-two">
          <el-image :src="top_pic" style="width: 343px; height: 360px;"></el-image>
        </div>
      </div>
    </div>
    <div class="second-center">
      <div class="second-center-title df-width">系统特点</div>
      <div class="second-center-desc df-width">SYSTEM CHARACTERISTICS</div>
      <div class="second-center-box df-width">
        <div class="second-center-item bg1">
          <div class="second-center-item-title">助力协会宣传</div>
          <div class="second-center-item-desc">便捷式的小程序客户端，易操作、易传播，无需下载、无需登录，可借助庞大的微信用户群体进行宣传，扩大协会影响力，吸纳新成员。</div>
        </div>
        <div class="second-center-item bg2">
          <div class="second-center-item-title">摒除冗余工作</div>
          <div class="second-center-item-desc">智能化系统服务，高效用户管理、活动管理、数据管理，支持数据实时更新、一键导入信息、快速生成报表，极大减少工作量。</div>
        </div>
        <div class="second-center-item bg3">
          <div class="second-center-item-title">优化成员服务</div>
          <div class="second-center-item-desc">实时在线发布消息、传递资讯、提供服务，建立协会成员互动互通的信息平台，提高参与率和活跃度，为成员提供更便捷的服务。</div>
        </div>
        <div class="second-center-item bg4">
          <div class="second-center-item-title">数据智能统计</div>
          <div class="second-center-item-desc">系统后台实时更新数据库，提供智能化数据处理分析功能，为协会进行信息化大数据统计，并形成动态可视化图表，帮助快速掌握核心关键信息。</div>
        </div>
      </div>
    </div>

    <div class="second-footer df-width">
      <div class="second-footer-title">功能介绍</div>
      <div class="second-footer-desc">FUNCTION INTRODUCTION</div>
      <div class="second-footer-box">
        <div class="second-footer-icon">
          <div class="second-footer-content">
            管理员在线编辑活动信息并发布活动，用户线上快捷报名，平台可实时统计报名名单。
          </div>
          <div class="second-footer-act">
            <el-image :src="icon1" style="width: 95.2px; height: 95.2px;"></el-image>
            <div class="second-footer-txt">活动报名</div>
          </div>
        </div>
        <div class="second-footer-icon">
          <div class="second-footer-content">
            根据用户在线上线下参加活动次数，在移动端实时排名，可查看每个用户参加的活动详情。
          </div>
          <div class="second-footer-act">
            <el-image :src="icon2" style="width: 95.2px; height: 95.2px;"></el-image>
            <div class="second-footer-txt">履职记录</div>
          </div>
        </div>
        <div class="second-footer-icon">
          <div class="second-footer-content">
            平台可将系统内的会员信息、活动报名、资讯、财务等数据形成可视化图表，能够更直观地展示给用户。
          </div>
          <div class="second-footer-act">
            <el-image :src="icon3" style="width: 95.2px; height: 95.2px;"></el-image>
            <div class="second-footer-txt">数据统计</div>
          </div>
        </div>
        <div class="second-footer-icon">
          <div class="second-footer-content">
            平台可提供会费缴纳的入口，有效保障了协会通知更灵活、收款更即时、财务对账更精准。
          </div>
          <div class="second-footer-act">
            <el-image :src="icon4" style="width: 95.2px; height: 95.2px;"></el-image>
            <div class="second-footer-txt">会费缴纳</div>
          </div>
        </div>
        <div class="second-footer-icon">
          <div class="second-footer-content">
            平台可展示协会成员参加的活动情况、获得荣誉或者奖项、取得的科研成果成绩等等。
          </div>
          <div class="second-footer-act">
            <el-image :src="icon5" style="width: 95.2px; height: 95.2px;"></el-image>
            <div class="second-footer-txt">成员风采</div>
          </div>
        </div>
        <div class="second-footer-icon">
          <div class="second-footer-content">
            记录所有用户的联系方式、企业职务、社会职务、基本资料等信息，用户可自行修改更新个人信息。
          </div>
          <div class="second-footer-act">
            <el-image :src="icon6" style="width: 95.2px; height: 95.2px;"></el-image>
            <div class="second-footer-txt">通讯录</div>
          </div>
        </div>
        <div class="second-footer-icon">
          <div class="second-footer-content">
            平台可提供通知公告、协会资讯、协会制度、协会政策等多个信息资讯端口给用户查阅。
          </div>
          <div class="second-footer-act">
            <el-image :src="icon7" style="width: 95.2px; height: 95.2px;"></el-image>
            <div class="second-footer-txt">资讯动态</div>
          </div>
        </div>
        <div class="second-footer-icon">
          <div class="second-footer-content">
            平台可提供详细的申请模板给游客填写提交，可通过管理后台审核资料，能够自动生成文档进行存档。
          </div>
          <div class="second-footer-act">
            <el-image :src="icon8" style="width: 95.2px; height: 95.2px;"></el-image>
            <div class="second-footer-txt">加入协会</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Second',
  data () {
    return {
      top_pic: require('@/assets/images/plan_second1.png'),
      bg: require('@/assets/images/plan_second2.png'),
      icon1: require('@/assets/images/plan_second7.png'),
      icon2: require('@/assets/images/plan_second8.png'),
      icon3: require('@/assets/images/plan_second9.png'),
      icon4: require('@/assets/images/plan_second10.png'),
      icon5: require('@/assets/images/plan_second11.png'),
      icon6: require('@/assets/images/plan_second12.png'),
      icon7: require('@/assets/images/plan_second13.png'),
      icon8: require('@/assets/images/plan_second14.png')
    }
  },
  methods: {
    goToUrl (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
.second-top {
  height: 400px;
  background: linear-gradient(-45deg, #3A639E, #65A1D0);
  display: flex;
  align-items: center;
}
.second-top-box {
  display: flex;
  align-items: center;
}
.second-top-one {
  color: #fff;
  flex: 1
}
.second-top-one-title {
  font-size: 40px;
  font-weight: bold;
}
.second-top-one-desc {
  font-size: 20px;
  margin-top: 8px
}
.second-top-one-content {
  width: 700px;
  font-size: 14px;
  line-height: 25px;
  margin-top: 26px;
}
.second-top-one-btn {
  border: 1px solid #fff;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 30px;
  cursor: pointer;
}
.second-center {
  background-image: url('~assets/images/plan_second2.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.second-center-title {
  padding-top: 72px;
  font-size: 40px;
  font-weight: bold;
  color: var(--color-text);
}
.second-center-desc {
  margin-top: 15px;
  color: #598EC1;
  font-size: 20px;
}
.second-center-box {
  margin-top: 145px;
  padding-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.second-center-item {
  width: 285px;
  height: 260px;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px 0px rgba(89, 142, 193, 0.3);
  border-radius: 10px;
}
.bg1 {
  background-image: url('~assets/images/plan_second3.png');
  background-repeat: no-repeat;
  background-size: 105%;
  background-position: top;
}
.bg2 {
  background-image: url('~assets/images/plan_second4.png');
  background-repeat: no-repeat;
  background-size: 105%;
  background-position: top;
}
.bg3 {
  background-image: url('~assets/images/plan_second5.png');
  background-repeat: no-repeat;
  background-size: 105%;
  background-position: top;
}
.bg4 {
  background-image: url('~assets/images/plan_second6.png');
  background-repeat: no-repeat;
  background-size: 105%;
  background-position: top;
}
.second-center-item-title {
  padding-top: 20px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  margin-bottom: 47px;
}
.second-center-item-desc {
  font-size: 16px;
  color: #221714;
  line-height: 30px;
  padding: 0 20px;
}
.second-footer-title {
  color: var(--color-text);
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 15px;
}
.second-footer-desc {
  color: #598EC1;
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
}
.second-footer-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 54px;
}
.second-footer-icon {
  cursor: pointer;
  margin-top: 35px;
  width: 300px;
  height: 140px;
  text-align: center;
  position: relative;
}
.second-footer-txt {
  color: var(--color-text);
  font-size: 20px;
  font-weight: bold;
  margin-top: -20px;
}
.second-footer-act {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  background-color: #fff;
  height: 100%;
  z-index: 999;
}
.second-footer-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 37px 20px 0;
  background-color: rgba(89, 142, 193, 0.05);
  color: var(--color-text);
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  cursor: pointer;
  opacity: 1;
}
.second-footer-icon:hover .second-footer-act {
  visibility: hidden;
}
</style>
